<template>
  <ItemList
    @addItem="addVolumeType"
    @removeItem="removeVolumeType"
    :items="volumeTypes"
    :isLoading="isLoadingList"
    noDataText="Nenhum Tipo encontrado"
    label="Tipo (Volume)"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    volumeTypes: [],
    isLoadingList: false,
  }),
  methods: {
    getVolumes() {
      this.isLoadingList = true
      this.$api
        .get('productiontypes')
        .then((res) => {
          this.volumeTypes = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addVolumeType(value) {
      this.$api
        .post('productiontype/', { name: value })
        .then((res) => {
          this.volumeTypes.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeVolumeType(item) {
      this.$api
        .post('productiontype/delete', item)
        .then(() => {
          let idx = this.volumeTypes.indexOf(item)
          this.volumeTypes.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.volumeTypes.length === 0) {
      this.getVolumes()
    }
  },
}
</script>

<style>
</style>